<template>
  <section class="our-brands">
    <div class="container">
      <span class="d-block section-heading text-center font-weight-normal">{{
        $t("our_brands")
      }}</span>
      <client-only>
        <Carousel
          :autoplay="true"
          :loop="true"
          :navigationEnabled="true"
          :perPage="noOfSlidePerPage"
          :scrollPerPage="false"
          :paginationEnabled="false"
          navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
          navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
        >
          <Slide
            class="
              col-6 col-md-4 col-lg-2
              d-flex
              justify-content-center
              align-items-center
              img-holder
            "
            v-for="(brand, index) of slider"
            :key="index"
            height="133px"
          >
            <b-link
              v-if="brand.category != null"
              :to="`/` + brand.category.url_path"
              ><img v-lazy="brand.logo" :alt="brand.title"
            /></b-link>
            <img
              style="filter: grayscale(100%)"
              v-if="brand.category == null"
              v-lazy="brand.logo"
              :alt="brand.title"
            />
          </Slide>
        </Carousel>
      </client-only>
    </div>
  </section>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

export default {
  name: "OurBrands",
  components: {
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,

    ClientOnly,
  },
  computed: {
    slider() {
      return this.$store.getters["product/getBrandSlider"];
    },
  },
  mounted() {
    if (!isServer) {
      this.setNoOfSlidePerPage();
      window.addEventListener("resize", this.setNoOfSlidePerPage);
      window.addEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.setNoOfSlidePerPage);
      window.removeEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  data: () => ({
    noOfSlidePerPage: 6,
  }),
  methods: {
    setNoOfSlidePerPage() {
      if (window.innerWidth < 768) {
        this.noOfSlidePerPage = 2;
      } else if (window.innerWidth > 767 && window.innerWidth < 992) {
        this.noOfSlidePerPage = 3;
      } else {
        this.noOfSlidePerPage = 6;
      }
    },
  },
};
</script>
